.main-div {
  zoom: 0.2;
}

.hide-event-dev {
  display: none !important;
}
.ant-switch-inner { 
    background:#ff8072 ;
}

.ant-switch-handle:not(.ant-switch-checked)::before {
    background:#E74C3C !important;
}

.ant-switch-checked .ant-switch-inner { 
    background:#a0ffc8 !important;
}

.ant-switch-checked .ant-switch-handle::before {
    background:#2ECC71 !important;
}

.ant-switch .ant-switch-handle {
    width: 20px !important;
    height: 20px !important;
    top: 0 !important;
}
.dropdown-header-menu {
  z-index: 99999 !important;
}
.dropdown-header .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
    padding: 8px 10px !important;
    text-align: center;
  }
  .dropdown-header   .ant-dropdown-menu .ant-dropdown-menu-title-content >a {
    text-decoration: none !important;
  }

  .bg-button-main {
    background-color: #0C1151 !important;
    color: #fff !important;
    padding: auto 40px !important;
    line-height: 34px !important;
    height: 50px !important;
  }

  .mt-30 {
    margin-top: 30px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mt-25 {
    margin-top: 25px !important;
  }

  .mb-25 {
    margin-bottom: 25px !important;
  }

  .pt-50 {
    padding-top: 50px !important;
  }

  .main-img {
    max-width: 500px !important;
  }

  .w-100 {
    width: 100% !important;
  }

  .mb-negative {
    margin-bottom: -80px !important;
  }
  
  #eapps-instagram-feed-3 > a {
    opacity: 0 !important;
    z-index: 0 !important;
  }

  .eapps-widget-toolbar, .eapps-instagram-feed-title {
    display: none !important;
  }

  .eapps-instagram-feed-posts-inner  {
    margin-bottom: 30px !important;
  }

  .eapps-instagram-feed-posts-grid-load-more-container {
    margin-bottom: 60px !important;
  } 
  .p-10 {
    padding: 10px !important;
  }
  .w-100 {
    width: 100% !important;
  }
  .eapps-instagram-feed-posts-grid-load-more-text {
    background: rgb(12, 17, 81);
    color: #fff;
    border-radius: 5px;
  }

  .slick-list {
    margin-bottom: 80px !important;
  }

  .commonninja-ribbon-link {
    display: none !important;
  }
  
  .slick-track {
    margin-bottom: 20px !important;
  }

  .rfm-initial-child-container {
    margin-top: 10px !important;
    margin-bottom: 20px !important;
  }

  .rfm-marquee-container {
    max-width: 1200px !important;
  }

  .export-btn {
    background-color: rgb(73, 77, 125) !important; 
    color: #fff !important;
  }

  .commonninja-ribbon {
    display: none !important;
  }

  .export_form .ant-checkbox-checked .ant-checkbox-inner {
    background-color: rgb(73, 77, 125) !important;
    border-color: rgb(73, 77, 125) !important;
  }

  .blue {
    color: rgb(73, 77, 125) !important;
  }

  .black {
    color: #000 !important;
  }

  .fs-18 {
    font-size: 18px !important;
  }

  .dropdown-header .ant-dropdown-menu-item:hover, .bg-blue-btn, .step-export .ant-steps-item-active .ant-steps-item-icon {
    background-color: rgb(73, 77, 125) !important;
    color: #fff !important;
  }

  .step-export .ant-steps-item-title::after {
    background-color: rgb(73, 77, 125) !important; 
  }

  .ant-result-title {
    font-size: 22px !important;
  }

.div-contactar {
  background: #fff; 
  border: 2px solid rgb(248, 132, 54);
  border-radius: 10px; 
  padding: 20px 20px; 
  display: flex; 
  justify-content: space-around; 
  flex-direction: column;
  cursor: pointer;
}

.div-contactar-nome-responsavel {
  background: #fff; 
  border: 2px solid rgb(248, 132, 54);
  border-radius: 10px; 
  padding: 5px; 
  display: flex; 
  justify-content: space-around; 
  flex-direction: column;
  cursor: pointer;
  margin-bottom: 30px;
}

.link-contacto {
  text-align: center;
  font-size: 12px;
  color: #000;
  font-weight: 400;
}

.div-contactar:hover {
  background: rgb(250 239 233) !important;
}

.div-contactar svg {
  width: 20px;
  height: 20px;
}

.modal-contactar .ant-modal-content {
  border-radius: 10px;
  padding: 30px;
  border: 3px solid rgb(248, 132, 54) !important;
}

.modal-contactar .ant-modal-footer {
  display: none !important;
}

.modal-contactar .ant-modal-title {
font-size: 14px;
}
.pre-loading-div svg {
  height: 50% !important;
  width: 50% !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.content__select {
  min-height: 45px !important;
}

.w-100 {
  width: 100%;
}

.fs-14 {
  font-size: 14px !important;
}

.ant-table-cell {
  vertical-align: top !important;
}


@media only screen and (max-width: 650px) {
  .d-desktop {
    display: none !important;
  }

  .mt-10-mobile {
    margin-top: 10px;
  }

  .mb-50-mobile {
    margin-bottom: 50px !important;
  }

  .align-center-mobile {
    text-align: center !important;
  }

  .file-group .ant-col-xs-24 {
    flex: none !important;
  }

  .file-group {
    justify-content: center !important;
    gap: 10px; 
  }
}

@media only screen and (min-width: 650px) {
  .d-mobile {
    display: none !important;
  }
}