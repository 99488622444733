.hide-event-dev {
  display: none !important;
}

.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
  border-radius: 50%;
  display: block !important;
  margin-left: auto !important;
  width: 100% !important;
}

.overflow-h .ant-drawer-body {
  overflow: hidden !important;
  padding: 12px;
}
.radio-event .ant-radio-button-wrapper {
  border: none;
  line-height: 45px;
}

.ant-upload-wrapper .ant-upload-select {
  width: 100% !important;
}

.radio-event .ant-radio-button-wrapper, .radio-event .ant-radio-group-large {
   height: 45px !important;
 }

.radio-event {
  border: 1px solid rgb(128, 159, 184) !important;
  border-radius: 8px;
}

.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder  {
  line-height: 40px !important;
}


.w100 {
  min-width: 100% !important;
}

.d-flex {
  display: flex !important;
} 

.align-center {
  align-items: center !important;
}

.ant-switch .ant-switch-handle {
  width: 20px;
  height: 20px;
}

#login-form .ant-input-affix-wrapper {
  padding: 0 !important;
}

#login-form  .ant-input-affix-wrapper >input.ant-input {
  height: 44px !important;
  text-align: center;
  border-radius: 6px !important;
}

#login-form .ant-input-suffix {
  height: 44px !important;
  position: absolute;
  right: 10px !important;
  background: transparent !important;
}
.ant-switch {
  height: 20px;
}


.h-45 {
  height: 45px !important;
}


.input-socio {
  border: 1px solid rgb(128, 159, 184) !important;
}

.bg-light-blue {
  background: #809FB8 !important;
  padding: 10px;
  border-radius: 5px;
  color: white;
  text-align: center;
  margin-bottom: 20px;
}

.input-socio input {
  color: #809FB8 !important;
  font-weight: bold !important;
}

:where(.css-dev-only-do-not-override-j0nf2s).ant-row {
  display: flex;
  /* flex-flow: row !important; */
}

.mb-0 {
  margin-bottom: 0 !important;
}

.align-center {
  text-align: center;
}

.row-drawer {
  flex-flow: column !important;
}

:where(.css-dev-only-do-not-override-j0nf2s).ant-btn {
  /* display: grid !important; */
  margin-left: auto;
  margin-right: auto;
}

.pointer-none {
  pointer-events: none !important;
}

.ant-switch.ant-switch-checked {
  background: '#494D7D' !important;
}

:where(.css-dev-only-do-not-override-j0nf2s).ant-input-affix-wrapper::before {
  content: none !important;
}

.ant-popover {
  z-index: 9999 !important;
}

.ant-select-selector .ant-select-selection-search-input {
  height: 42px !important;
}

.ant-select-selector {
  min-height: 40px;
  width: 150px !important;
  border-radius: 6px !important;
  
  border: 1px solid rgb(128, 159, 184) !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 42px;
}

.ant-radio-group  {
  width: 100%;
}

.ant-radio-button-wrapper {
  width: 50% !important;
  text-align: center;
}

.ant-radio-button-wrapper-checked {
  background: rgb(73, 77, 125) !important;
  color: #fff !important;
}

#register-form .ant-select-selection-item {
  display: block;
  margin-top: auto !important;
  font-weight: bold !important;
  color: rgb(128, 159, 184) !important;
  margin-bottom: auto !important;
}

.ant-message-notice {
  text-align: right !important;
}

.ant-btn-primary:disabled span {
  opacity: 0.75 !important;
}

.ant-upload-drag-container p {
  text-align: center !important;
}

:where(.css-dev-only-do-not-override-j0nf2s).ant-tour-placement-bottom .ant-tour-arrow {
  left: 80% !important;
}

:where(.css-dev-only-do-not-override-j0nf2s).ant-tour .ant-tour-content {
  margin-right: 15px !important;
}

.ant-message-notice-content {
  padding-top: 16px !important;
  padding-right: 30px !important;
  padding-left: 12px !important;
}

.ant-notification-notice-success {
  background: #a1dda1 !important;
}

.ant-notification-notice-error {
  background: #ffb9b9 !important;
}

.ant-btn span {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

/* .ant-modal-content .ant-btn span {
  color: #fff !important;
} */

.ant-notification-notice {
  padding-top: 16px !important;
  padding-right: 45px !important;
  padding-left: 12px !important;
  min-height: 50px;
  margin-bottom: 0 !important;
}

.ant-input-suffix {
  width: 10%;
  height: 35px;
  background: #494D7D;
  color: #fff;
  border-radius: 6px;
  display: flex;
  justify-content: center;
}

.ant-select, .ant-select-selector {
  width: 100% !important;
  /* border: 1px solid rgb(128, 159, 184); */
  border-radius: 6px;
  color: '#0C1151';
}

.modal-anticorpos .ant-modal-footer, .modal-novo-clube .ant-modal-footer,
.modal-set-member .ant-modal-footer {
  display: none !important;
}

.modal-set-member .ant-modal-content {
  padding: 0px 24px !important;
}

.ant-select-selection-placeholder {
  height: 50px !important;
}

.ant-switch-inner { 
  background:#ff8072 ;
}

.ant-switch-handle:not(.ant-switch-checked)::before {
  background:#E74C3C !important;
}

.ant-switch-checked .ant-switch-inner { 
  background:#a0ffc8 !important;
}

.ant-switch-checked .ant-switch-handle::before {
  background:#2ECC71 !important;
}

.ant-select-selection-placeholder {
  padding-top: 7px !important;
}

.ql-toolbar {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border: 1px solid #809FB8 !important;
}

.ql-container {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  min-height: 150px;
  border: 1px solid #809FB8 !important;
}

:where(.css-dev-only-do-not-override-j0nf2s).ant-upload-wrapper.ant-upload-picture-card-wrapper {
  width: auto !important;
}