.payment_options .ant-steps {
  display: none !important;
}

.payment_method .ant-radio-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment_method span:nth-child(2) {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.payment_method span:nth-child(2) img {
  max-height: 40px;
  min-height: 40px;
  margin: 0 10px;
}

.payment_button {
  width: 175px;
  height: 40px;
}

.payment_button.back {
  background-color: #FFF;
  border: 1px solid #ec5566;
  color: #ec5566;
  transition: .3s ease-out;
}

.payment_button.back:hover {
  background-color: #ec5566;
  border: 1px solid #ec5566;
  color: #fff;
}

.payment_options .ant-form-item-explain-error {
  text-align: center;
}

.payment_options #payment-form-step-two .ant-form-item-explain-error {
  text-align: left !important;
  margin-bottom: 0 !important;
}

.payment_options .payment_card_group {
  max-width: 650px;
  margin-top: 40px;
  margin-bottom: 20px;
}

.payment_options .payment_card_group .payment_card {
  padding: 20px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  max-width: 300px;
  justify-content: flex-start;
  align-items: center;
  background: #F3F6F9;
  box-shadow: 0px 3px 10px #00000029;
}

.payment_options .payment_card_group .payment_card.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.payment_options .payment_card_group .payment_card span:nth-child(2) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.payment_options .payment_card_group .payment_card p {
  margin: 0 !important;
}

.payment_options .step_reference {
  background: #F3F6F9;
  padding: 30px;
}

.payment_options .step_reference img {
  max-width: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment_options .step_reference p.payment_info {
  margin: 5px 0;
}

.payment_options .step_reference p.payment_info.date_expiry {
  margin: 35px 0 0 0;
}

.payment_options .title {
  font-size: 20px;
  color: #4A4C4D;
  font-weight: 600;
  margin-bottom: 10px;
}

.payment_options .img_method {
  width: 100%;
  max-width: 140px;
  margin-bottom: 20px;
}

.payment_options .img_method.debit {
  width: 100%;
  max-width: 40px;
  margin-bottom: 20px;
  margin-right: 20px;
}

.payment_options .img_method.mbway {
  width: 100%;
  max-width: 110px !important;
  margin-bottom: 20px;
}

.payment_options .warning_text {
  font-size: 12px;
  color: #4A4C4D;
  margin-top: 20px;
  margin-bottom: 30px;
}

#payment-form-step-two .ant-form-item {
  margin: 0 !important;
}

#payment-form-step-two .ant-form-item .warning_price {
  margin-bottom: 0 !important;
}

#payment-form-step-two .ant-form-item label {
  font-weight: 500;
}

#payment-form-step-two .ant-form-item label::before {
  display: none !important;
}